import { render, staticRenderFns } from "./recoveryForm.vue?vue&type=template&id=8942064a&scoped=true"
import script from "./recoveryForm.vue?vue&type=script&lang=js"
export * from "./recoveryForm.vue?vue&type=script&lang=js"
import style0 from "./recoveryForm.vue?vue&type=style&index=0&id=8942064a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8942064a",
  null
  
)

export default component.exports
<template>
  <div  id="recoveryBlock" class="align">
    <loader v-show="isLoading"></loader>
    <div  class="contentBlock"  v-show="!isLoading">
      <div class="title">
        <h3>Воcстановление пароля</h3>
      </div>
      <div class="body">
        <div class="inputBlock">
          <input type="tel" id="login2"
                 v-model.trim="PhoneNumber"
                 v-mask="'+7 (###) ###-##-##'">
          <label for="login2" v-bind:class="{showLabel: PhoneNumber.length === 0}">Логин(Номер телефона)</label>
          <div v-show="errorlogin.length > 0" v-html="errorlogin" class="errMsg"></div>
          <p class="stxt">На ваш номер телефона будет отправлено СМС с кодом подтверждения</p>
        </div>
        <div v-show="responseError.length > 0" v-html="responseError" class="errMsg"></div>
      </div>

      <div class="contentFooter">

        <div id="navLoginWrap"  v-show="!isLoading">
          <router-link :class="'linkColor'" to="/login">Вернуться назад</router-link>
        </div>
        <button v-on:click="sendAction" v-show="!isLoading">Отправить смс</button>
      </div>

    </div>

  </div>

</template>

<script>
  import {mapState} from 'vuex'
  export default {
    name: "recoveryForm",
    data(){
      return {
        isLoading:false,
        errorlogin: '',
        responseError:'',
      }
    },
    methods:{
      checkLogin() {
        if (this.$bikeFunction.correctPhone(this.PhoneNumber)) {
          this.errorlogin = '';
          return true;
        } else {
          this.errorlogin = 'Некорректный логин';
          return false;
        }
      },
      sendAction(){
        if (this.checkLogin()){
          this.isLoading = true;
          this.$external.universalRequest({
            externalType: 'zita',
            action: 'recoveryPass',
            PhoneNumber: this.PhoneNumber
          }).then((res) => {
            this.$store.commit('setUserID', res.UserID);
            localStorage.setItem('countdown', '60');
            this.$router.push('ConfirmationCode');
          })
          .catch((res) => {
            if ( res.errorCode > 0 || res.errorCode !== undefined ) {
              alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
            }
            try {
              this.responseError = res.errorMsg;
            } catch (e) {
              this.responseError = JSON.stringify(res);
            }
          })
          .finally(()=>{
            this.isLoading = false;
          });
        }

      }
    },
    computed:{
      PhoneNumber:{
        get(){
          return this.loginVuex;
        },
        set(value){
          this.$store.commit('setLogin', value)
        }
      },
      ...mapState({
        loginVuex: state => state.auth.PhoneNumber,
        passwordVuex: state => state.password,
      })
    },
    components: {
      loader: () => import('@/components/other/loader'),
    },
    mounted() {
      this.isLoading = false;
      this.$myApi.checkVersion();

      let params = this.$route.query;

      if(params['phone'] !== undefined && params['phone'].length === 10){
        this.$store.commit('setLogin', params['phone'])
      }
    }
  }
</script>

<style scoped>
/** { line-height: normal; letter-spacing: unset }*/
/*.align { width: 87.35vw; margin: 0 auto }*/
/*.contentBlock { box-shadow: 0 1.18vw 8.82vw 0 rgba(0, 0, 0, 0.10); border-radius: 16px; padding: 3.82vw 6.18vw 0; margin-bottom: 5.88vw}*/
/*.contentBlock a { color: #43AC34; text-decoration-line: underline; }*/
/*.contentBlock .title { border-bottom: none; margin-bottom: 4.41vw }*/
/*.contentBlock h3 { font-family: 'Lato'; margin: 0; font-size: 6.18vw }*/
/*#recoveryBlock .inputBlock { position: relative; margin: 2vw 0; }*/
/*#recoveryBlock .inputBlock>input { background: none; border: 2px solid rgba(67, 172, 52, 0.3); box-sizing: border-box; border-radius: 8px; outline: 0; padding: 5vw 5vw 2vw; font-family: 'Roboto'; font-size: 5vw; width: 100%; margin-bottom: 3.53vw }*/
/*#recoveryBlock .inputBlock>label { position: absolute; left: 5vw; top: 1vw; color: rgba(51, 51, 51, 0.54); pointer-events: none; transition: all .2s ease; font-size: 3vw;  }*/
/*#recoveryBlock .layoutBody { padding: 0; margin-bottom: 4.12vw }*/
/*#recoveryBlock .layoutFooter { padding: 0 0 4.41vw 0; border-top: 0; display: flex; align-items: flex-end; justify-content: space-between }*/
/*#recoveryBlock button { font-family: 'Ubuntu'; font-weight: bold; font-size: 3.24vw; box-shadow: none; padding: 3.53vw 3.82vw }*/
/*#recoveryBlock a { color: #43AC34; text-decoration-line: underline; margin-bottom: 0; font-size: 3.82vw }*/
/*#recoveryBlock p { font-size: 1.96vw; color: #959595; margin-bottom: 0 }*/
@media screen and (min-width: 760px){

}
@media screen and (min-width: 1239px){
  /*.align { width: 1085px }*/
  /*.contentBlock a {  }*/
  /*.contentBlock { padding: 15px 21px 0; margin-bottom: 17px; box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.10);}*/
  /*.contentBlock .title { margin-bottom: 15px }*/
  /*.contentBlock h3 { font-size: 27px }*/
  /*#recoveryBlock .inputBlock { margin: 0; }*/
  /*#recoveryBlock .inputBlock>input { box-sizing: border-box; outline: 0; padding: 17px 11px; font-family: 'Roboto'; font-size: 14px; width: 100%; margin-bottom: 16px }*/
  /*#recoveryBlock .inputBlock>label { left: 11px; top: 3px; pointer-events: none; font-size: 14px;  }*/
  /*#recoveryBlock .layoutBody { padding: 0; margin-bottom: 17px }*/
  /*#recoveryBlock .layoutFooter { padding: 0 0 26px 0 }*/
  /*#recoveryBlock button { font-size: 15px; padding: 17px 19px }*/
  /*#recoveryBlock a { font-size: 18px }*/
  /*#recoveryBlock p { font-size: 14px }*/
  /*#recoveryBlock .contentBlock { width: 532px }*/
}
</style>
